<template>
  <div class="home">
    <HeroVideo id="HeroVideo_"/>

    <FeatureCard
      Card_title="가치타"
      Card_subtitle="IoT 기반 차량 데이터 수집 솔루션"
      Card_description="IoT 기반 차량 데이터 수집 솔루션을 통해 차량의 다양한 데이터를 수집하고, 원격으로 고장 진단 수행이 가능 합니다."
      ImageSrc="main_genswell.png"
      routeTo="/Gachita"
      id="FeatureCard_"/>

    <FeatureCard2
      ImageSrc="main_gachita.png"
      Card_title="가치타"
      Card_subtitle="차량 관리 솔루션"
      Card_description="차량 운행 및 상태의 효과적인 관리를 통해 차량과 물류를 하나로 묶어 통합 관리하는 관제 솔루션입니다."
      routeTo="/Gachita"
      id="FeatureCard_"/>

  </div>
</template>

<script>
// @ is an alias to /src
import HeroVideo from '@/components/home/HeroVideo.vue'
import FeatureCard from '@/components/home/FeatureCard.vue'
import FeatureCard2 from '@/components/home/FeatureCard2.vue'
import MassComs from '@/components/home/MassComs.vue'


export default {
  name: 'home',
  components: {
    HeroVideo,
    FeatureCard,
    FeatureCard2,
    MassComs,
  },
  data:()=>
  {
    return {
    }


  }
}
</script>

<style scoped>

</style>
