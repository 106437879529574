<template>
    <div id="Customed">

    <div class="container" v-on:click="ReservedEvent()">
        <div class="row">
            <div class="col-1 align-content-start">
                <img src="@/assets/gachita.png" class="Logo"/>
            </div>
        </div>
        <div id="FooterDetails">
            <div class="row justify-content-between">
                <div class="col-8">
                    <div class="row">
                        <div class="col" id="companyInfo">
                          경기도 성남시 수정구 성남대로 1342 가천대학교 창업보육센터 B110호
                        </div>
                        <div class="w-100"></div>
                        <div class="col" id="companyInfo">
                            대표자명: 정우철
                        </div>
                        <div class="w-100"></div>
                        <div class="col" id="companyInfo">
                            문의: wc.jung@gachita.com
                        </div>
                        <div class="w-100"></div>
                        <div class="col" id="copyrights">
                            <p class="d-none d-sm-block">
                                Copyright © 2023 GACHITA. All Right Reserved
                            </p>
                            <p class="d-block d-sm-none">
                                <br>Copyright © 2023 GACHITA.<br>All Right Reserved
                            </p>

                        </div>
                    </div>
                </div>

                <div class="col-3 d-none d-md-block">
                    <div class="row sitemap">
                        <router-link to="/">
                        <div class="col" id="footerNav">
                            Home
                        </div>
                        </router-link>

                        <div class="w-100"></div>

                        <div class="w-100"></div>

                        <router-link to="/Gachita">
                        <div class="col" id="footerNav">
                            차량 제어 솔루션
                        </div>
                        </router-link>

                        <div class="w-100"></div>

                        <router-link to="/Contact">
                        <div class="col" id="footerNav">

                            Contact
                        </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="row align-items-end" style="margin-top:16px; margin-bottom:16px;">
                <div class="d-block d-md-none col-12 ">
                        <router-link to="/">
                            <div id="footerNav">
                                Home
                            </div>
                        </router-link>
                </div>
                <div class="d-block d-md-none col-12 ">
                        <router-link to="/Gachita">
                            <div id="footerNav">
                                차량 제어 솔루션
                            </div>
                        </router-link>
                </div>
                <div class="d-block d-md-none col-12 ">
                        <router-link to="/contact">
                            <div id="footerNav">
                                Contact
                            </div>
                        </router-link>
                </div>

                <div class="d-none d-md-block col-6">
                    <div class="row agreements">
                        <div class="col">
                            <!-- 회원이용약관 -->
                        </div>
                        <div class="w-100"></div>
                        <div class="col">
                            <!-- 개인정보 처리방침 -->
                        </div>
                        <div class="w-100"></div>
                        <div class="col">
                            <!-- 위치기반 서비스 이용약관 -->
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>

    </div>
</template>
<script>
export default {
    name: 'CustomFooter',
    methods:{
        ReservedEvent(){
            // console.log(this.$router.currentRoute.path);
        }
    }
}

</script>
<style scoped>
#dropdownItems{
    width: 90.92px;
}

.agreements{
  font-family: 'NanumSquare';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: #636e72;
  text-decoration-line: underline
}
.agreements:hover{
    text-decoration-line: underline
}

#custom-dropdown{
  border-radius: 6px;
  background-color: #ccd0d1;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  color: #2d3436;
  min-height: 44px;
}

.sitemap{
  font-family: 'NanumSquareB';
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #2d3436;
}
.Logo{
  width: 119px;
  height: 20px;
  object-fit: contain;
  margin-bottom: 35px;
}
#Customed{
    margin-top: 36px;
    position: relative;
    bottom: 0;
    min-height: 297px;
    background-color: #ecf0f1;
    padding-top: 60px;
}
#FooterDetails{
    /* white-space: nowrap; */
    text-align: left;
  font-family: 'NanumSquare';
  font-size: 15px;

  font-stretch: normal;
  font-style: normal;
  line-height: 2.13;
  letter-spacing: normal;
  color: #2d3436;
  padding-bottom: 36px;

}
#footerNav{
    margin-bottom: 2px;
    color: #2d3436;
    text-decoration-line: none;
    font-weight: bolder;
}
:hover{
    text-decoration-line: none;
}
#companyInfo{
    margin-bottom: 16px;
    line-height: 1.5;
}
#copyrights{
    margin-bottom: 2px;
    white-space: nowrap;
    line-height: 1.5;
}

a:hover{

  text-decoration: none !important;
}
</style>
