<template>
    <div class="container" id="FeatureCardContainer">
    <router-link id="navText_main" :to="{path: routeTo}">
        <div class="row " id="topPositionHelper">
            <div class="d-none d-sm-block col-12 col-sm-12" id="FeatureImgCard">
                <img id="FeatureImage" class="rounded" :src="require('@/assets/'+ImageSrc)" />
            </div>

            <div class="d-none d-sm-block offset-md-1 col-md-7 col-lg-5 col-xl-5 card" id="FeatureExplanation">
                <div class="card-body">
                    <div class="row" id="FeatureCardText">
                        <div class="col" id="FeatureCardTitle">
                          가치타
                        </div>

                        <div class="w-100"></div>
                        <div class="col" id="FeatureCardSubtitle">
                          IoT 기반 차량 데이터 수집 솔루션
                        </div>

                        <div class="w-100"></div>
                        <div class="d-block d-lg-none col-sm-9 col-md-10" id="FeatureCardDescription">
                          IoT 기반 차량 데이터 수집 솔루션을 통해 차량의 다양한 데이터를 수집하고, 원격으로 고장 진단 수행이 가능 합니다.
                        </div>
                        <div class="d-none d-lg-block col-lg-12" id="FeatureCardDescription">
                            <p>IoT 기반 차량 데이터 수집 솔루션을 통해 차량의 다양한 데이터를 수집하고, 원격으로 고장 진단 수행이 가능 합니다.</p>
                        </div>
                    </div>
                </div>
                <img src="@/assets/arrow.svg" id="Icon_arrow">
            </div>
            <div class=" d-xs-block d-sm-none col-12 card" id="sm_FeatureExplanation">
                <div class="card-body">
                        <img id="FeatureImage_xs" class="rounded" :src="require('@/assets/'+ImageSrc)" />

                        <div class="row" id="FeatureCardText">
                            <div class="col" id="FeatureCardTitle">
                                {{Card_title}}
                            </div>
                            <div class="w-100"></div>
                            <div class="col" id="FeatureCardSubtitle" style="font-size:32px;">
                                {{Card_subtitle}}
                            </div>
                            <div class="w-100"></div>
                            <div class="col" id="FeatureCardDescription">
                                {{Card_description}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

    </router-link>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'FeatureCard',
  components: {

  },
  methods:{
    }
  ,
  computed:{
  }
  ,
  props: {
   'Card_title': String,
   'Card_subtitle': String,
   'Card_description': String,
   'ImageSrc' : String,
   'routeTo' :String
  }
  ,
  data : ()=>
  {
    return {}
    // if(true)                // depending on card selection
    // {

        // return {
        //     Card_title: "젠스웰",
        //     Card_subtitle: "사운드 제어 솔루션",
        //     Card_description: "차량 운전의 즐거움을 고객에게 전달하기 위한 다양한 사운드 솔루션입니다.",

        // }

    // }
    // else
    // {

        // return {
            // Card_title: "가치타",
            // Card_subtitle: "차량 관리 솔루션",
            // Card_description: "차량 운행 및 상태의 효과적인 관리를 통해 차량과 물류를 하나로 묶어 통합 관리하는 관제 솔루션입니다.",

        // }

    // }

  }
}
</script>

<style scoped>
#FeatureExplanation:hover > img#Icon_arrow{
    display: block;
    z-index: 1;
    transform: translateX(48px);
    transition: all .15s linear;

}

#FeatureImgCard:hover + div img#Icon_arrow{
    display: block;
    z-index: 1;
    transform: translateX(48px);
    transition: all .1s linear;
}

#Icon_arrow{
    display: block;
    z-index: -1;
    position: absolute;
    right: 64px;
    bottom: 24px;

}

.card{
    border: 0px solid transparent;
}
a#navText_main:hover{
  text-decoration: none !important;
  /* white-space: nowrap; */
  color: #ffffff;
}
#FeatureImgCard {
    padding: 0px;

  display: inline-block;
  overflow: hidden;            /* clip the excess when child gets bigger than parent */
}
#FeatureImgCard img {
  /* display: flex; */
  transition: transform .4s;   /* smoother zoom */
}
#FeatureImgCard:hover img {

    text-decoration: none;
  transform: scale(1.1);
  transform-origin: 50% 50%;
}
#FeatureImgCard:hover + #FeatureExplanation {

    text-decoration: none;
    box-shadow: -20px 42px 30px 0 rgba(29, 145, 232, 0.2);
}

#FeatureCardText{
    text-align: left;
}

#FeatureCardContainer{
    position: relative;
    /* height: auto; */
    width: 100%;
    min-width: 320px;
}
#FeatureImgCard{

    position: relative;
    min-height: 500px;
    /* top: -108px; */
    /* top: -10%; */
    margin-bottom: 72px;
}
#FeatureExplanation{

    position: relative;
    top: -172px;
    /* top: -10%; */
    min-height: 260px;
    border-radius: 5px;
    border-color: transparent;
     box-shadow: -20px 20px 30px 0 rgba(0, 0, 0, 0.06);
     background-color: #ffffff;
}
#FeatureExplanation:hover{

    text-decoration: none;
    box-shadow: -20px 42px 30px 0 rgba(29, 145, 232, 0.2);
}
#topPositionHelper{
    position: relative;
    top: -64px;
    height: auto;
}
#sm_FeatureExplanation{
    min-width: 320px;
    /* max-height: 450px; */
    /* top: 10vh; */
    margin-top: 12px;
    margin-bottom: 24px;
}
#FeatureImage{
  width: 100%;
  max-height: auto;
  object-fit: cover;
}
#FeatureImage_xs{
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 16px;
}

#FeatureCardTitle{
  font-family: 'NanumSquareB';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d55e8;
}

#FeatureCardSubtitle{
  font-family: 'NanumSquare';
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151819;


  margin-top: 12px;
  margin-bottom: 18px;
}

#FeatureCardDescription{
    word-break: keep-all;
  font-family: 'NanumSquare';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2d3436;
}

</style>
