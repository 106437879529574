<template>
    <div>
        <div class="embed-responsive" id="heroVideoWrapper">
            <video class=".embed-responsive-item" id="heroVideo" poster="@/assets/poster.png" playsinline autoplay muted loop preload="none">
                <source src="@/assets/loop.mp4" type="video/mp4">
                <source src="@/assets/loop.webm" type="video/webm">
            </video>
            <div id="video_container"></div>
        </div>

        <div class="container">


            <div id="Greeting" class="row align-items-center justify-content-center">
                <div class="d-none d-md-block" id="md_margining_block">_</div>

                <div class="w-100"></div>



                <div class="col d-none d-md-block col-10" id="Greeting_title">
                    {{ Greeting_title }}
                </div>
                <div class="col d-block d-md-none col-8" id="Greeting_title_xs">
                    {{ Greeting_title }}
                </div>
                <div class="w-100 d-none d-md-block"></div>
                <div class="d-none d-md-block" id="md_margining_block">_</div>

                <div class="d-block d-md-none col-10" id="Greeting_subtitle_xs">
                    {{ Greeting_subtitle }}
                </div>

                <div class="d-none d-md-block d-lg-none  d-md-block col-md-7" id="Greeting_subtitle">
                    {{ Greeting_subtitle }}
                </div>
                <div class="d-none d-lg-block col-lg-5" id="Greeting_subtitle_lg">
                    {{ Greeting_subtitle }}
                </div>
                <!-- <div class="w-100"></div>
                <div class="d-none d-lg-block col-lg-4" id="Greeting_subtitle">
                    {{ Greeting_subtitle2 }}
                </div> -->
                <div class="d-none d-md-block w-100"></div>
                <div class="d-none d-md-block" id="md_margining_block">_</div>

                <div class="d-none d-md-block col-md-4 col-lg-3">
                    <router-link id="BTN_GETINTOUCH" to="/Contact"><button type="button" class="btn btn-block btn-outline-light">GET IN TOUCH</button></router-link>
                </div>
                <div class="d-xs-block d-md-none col-9 " id="xs_marginer">
                    <router-link id="BTN_GETINTOUCH_xs" to="/Contact"><button type="button" class="btn btn-block btn-outline-light">GET IN TOUCH</button></router-link>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HeroVideo',
  components: {

  },
  mounted(){
      document.getElementById("heroVideo").play();
  },
  data : ()=>
  {
      return {
          Greeting_title: "모빌리티 데이터 기반 서비스 플랫폼.",
          Greeting_subtitle: "가치타는 모빌리티 서비스를 위한 원격 데이터 취득/분석/고장 진단 솔루션을 제공합니다.",
          Greeting_subtitle1: "사운드 제어관련 플랫폼과 차량관련 서비스의 융합을 통해",
          Greeting_subtitle2: "새로운 가치 창출을 지향합니다.",
        //   Greeting_title_md: "",
        //   Greeting_title_lg: ""

      }
  }
}
</script>

<style scoped>

#heroVideoWrapper{
    min-width: 320px;
    min-height: 568px;
    height: 100vh;
}


#heroVideo{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
min-height: 568px;
min-height: 100%;
height: auto;
width: auto;
}



#Greeting{
    min-width: 320px;
    position: absolute;
    top: 0;
    left: 0;
    margin:0px;
    padding: 0px;
}




#video_container{
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 568px;
    height: 100vh;
    background-color: #000000;
    opacity: 0.5;
}

#xs_marginer{

    position: relative;
    margin: 45px 0px;
    padding: 0px;
}
.container{
    display: block;
    min-width: 320px;
    padding: 0px;
    margin: 0px;
}
.row{
    width: 100%;
}

#BTN_GETINTOUCH_xs{
    margin: 0;
    padding: 0;
    border-color: #ffffff;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
    text-decoration: none;
}
#BTN_GETINTOUCH_xs:hover{
    border-color: #ffffff;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;

}
#BTN_GETINTOUCH_xs:active{
    border-color: #ffffff;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
}
#BTN_GETINTOUCH{
    margin: 0 auto;
    max-width: 256px;
    border-color: #ffffff;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
    text-decoration: none;
}
#BTN_GETINTOUCH:hover{
    border-color: #ffffff;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;

}
#BTN_GETINTOUCH:active{
    border-color: #ffffff;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
}


/* #heroVideo{
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh;
    height: 100vh;
    object-fit: cover;
} */

#md_margining_block{
    padding: 0px;
    margin-bottom: 5vh;
    background: transparent;
    color: transparent;
}

/*
#Greeting_title_lg{
  font-family: 'NanumSquare';
  font-size: 67px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 5%;

}

#Greeting_title_md{
  font-family: 'NanumSquare';
  font-size: 67px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 5%
} */

#Greeting_title_xs{
  margin-top: 84px;

  font-family: 'NanumSquare';
  font-size: 41px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  word-break:keep-all;
  left: 0;
  right: 0;
  padding: 0;
}

#Greeting_title{
  font-family: 'NanumSquare';
  font-size: 67px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  word-break:keep-all;
  left: 0;
  right: 0;
  padding: 0;
  margin-top: 20vh;
  margin-bottom: 3vh;
}

#Greeting_subtitle_xs{
  font-family: 'NanumSquare';
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  word-break:keep-all;
  margin-top: 37px;
}
#Greeting_subtitle{
  font-family: 'NanumSquare';
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  word-break:keep-all;
  margin-bottom: 3vh;
}

#Greeting_subtitle_lg{
  font-family: 'NanumSquare';
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  max-width: 545px;
  min-width: 545px;
  margin-bottom: 3vh;

  word-break:keep-all;
}
</style>
