import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './../node_modules/jquery/dist/jquery.min.js';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../node_modules/bootstrap/dist/js/bootstrap.min.js';

Vue.config.productionTip = false

import naver from 'vue-naver-maps';
Vue.use(naver, {
	  clientID: 's7lbrbs1u3',
	  useGovAPI: false, //공공 클라우드 API 사용 (선택)
	  subModules:'' // 서브모듈 (선택)
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
