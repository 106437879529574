<template>
    <div>
        <div class="container d-none d-md-block" id="MassComsContainer">
            <div class="row">
                
                <div class="col " id="Line"></div>
                <div class="col-md-3 col-sm-5" id="MassComsTitle">보도자료 및 컨텐츠</div>
                <div class="col " id="Line"></div>
                

            </div>
            <div class="row justify-content-between" id="MassComsList">
                 <a class="col-2 masscomLogo inline_images"  href="https://www.hankyung.com/economy/article/2018010998862">
                <img src="@/assets/logo-of-consumer-electronics-show-svg@3x.png" id="logo_img"
                    srcset="@/assets/logo-of-consumer-electronics-show-svg@2x.png 2x,
                            @/assets/logo-of-consumer-electronics-show-svg@3x.png 3x"
                    class="img-fluid "/>
                 </a>
                 
                <a class=" col-2 masscomLogo inline_images"  href="http://m.munhwa.com/mnews/view.html?no=2018052401032030115001">
                <img src="@/assets/200-px@3x.png" id="logo_img"
                    srcset="@/assets/200-px@2x.png 2x,
                            @/assets/200-px@3x.png 3x" class="img-fluid  "/>
                </a>
                <a class=" col-2 masscomLogo inline_images"  href="https://mnews.joins.com/amparticle/23493957">
                <img src="@/assets/j@3x.png" id="logo_img"
                        srcset="@/assets/j@2x.png 2x,
                                @/assets/j@3x.png 3x" class="img-fluid join"/>
                </a>
            </div>

            <div class="row justify-content-between" id="MassComsList">
                <a  class=" col-2 masscomLogo inline_images"  href="http://www.fnnews.com/news/201906091121233738">
                <img src="@/assets/139353-202907-1753@3x.png" id="logo_img"
                        srcset="@/assets/139353-202907-1753@2x.png 2x,
                                @/assets/139353-202907-1753@3x.png 3x" class="img-fluid "/>
                </a>

                <a class=" col-2 masscomLogo inline_images"  href="https://platum.kr/archives/122640">
                <img src="@/assets/platum@3x.png" id="logo_img"
                    srcset="@/assets/platum@2x.png 2x,
                            @/assets/platum@3x.png 3x" class="img-fluid "/>
                </a>
                
                <a class=" col-2 masscomLogo inline_images" href="https://www.youtube.com/watch?v=jRowaJv_XYg">
                <img src="@/assets/top-logo-2@3x.png" id="logo_img"
                srcset="@/assets/top-logo-2@2x.png 2x,
                        @/assets/top-logo-2@3x.png 3x" class="img-fluid"/>
                </a>
            </div>

            <div class="row justify-content-between" id="MassComsList">
                <a  class="col-2 masscomLogo inline_images " href="http://newsroom.hcs.com/mfront/board/%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85%EA%B3%BC-%EB%8C%80%EA%B8%B0%EC%97%85%EC%9D%B4-%ED%95%A8%EA%BB%98-%EC%84%B1%EC%9E%A5%ED%95%98%EB%8A%94-%EC%83%9D%ED%83%9C%EA%B3%84%EB%A5%BC-%EA%BF%88%EA%BE%BC%EB%8B%A4">
                    <img src="@/assets/hdcardlogo.jpg" id="logo_img" class="img-fluid "/>
                </a>

                <a class=" col-2 masscomLogo inline_images" href="https://tv.hmgjournal.com/MediaCenter/News/Group-Broadcast/news-02-191104.blg">
                <img src="@/assets/hmg.jpg" id="logo_img" class="img-fluid "/>
                </a>
              
            </div>

        </div>
            <div class="container d-block d-md-none" id="MassComsContainer_xs">
            <div class="row" style="margin:0px; padding:0 32px 0 32px;">
                
                <div class="col" id="Line"></div>
                <div class="col-7" id="MassComsTitle_xs">보도자료 및 컨텐츠</div>
                <div class="col" id="Line"></div>
                <!-- <div class="col-1"></div> -->
            </div>
            <div class="row justify-content-center" id="MassComsList_sm">
                <div class="w-100"></div>
                <a class=" col-6 masscomLogo masscomLogo_sm" href="https://www.hankyung.com/economy/article/2018010998862">
                <img id="logo_img" src="@/assets/masscoms_1.png" class="img-fluid"/>
                
                </a>
                <div class="w-100"></div>
                <a class=" col-6 masscomLogo masscomLogo_sm" href="http://m.munhwa.com/mnews/view.html?no=2018052401032030115001">
                <img id="logo_img" src="@/assets/masscoms_2.png" class="img-fluid "/>
                </a>
                <div class="w-100"></div>
                <a class=" col-6 masscomLogo masscomLogo_sm" href="https://mnews.joins.com/amparticle/23493957">
                <img id="logo_img" src="@/assets/masscoms_3.png" class="img-fluid join"/>
                </a>
                <div class="w-100"></div>
                <a class=" col-6 masscomLogo masscomLogo_sm" href="http://m.fnnews.com/news/201906091121233738">
                <img id="logo_img" src="@/assets/masscoms_4.png" class="img-fluid"/>
                </a>
                <div class="w-100"></div>
                <a class=" col-6 masscomLogo masscomLogo_sm" href="https://platum.kr/archives/122640">
                <img id="logo_img"  src="@/assets/masscoms_5.png" class="img-fluid"/>
                </a>
                <div class="w-100"></div>
                <a class=" col-6 masscomLogo masscomLogo_sm" href="https://www.youtube.com/watch?v=jRowaJv_XYg">
                <img id="logo_img" src="@/assets/masscoms_6.png" class="img-fluid"/>
                </a>
                <div class="w-100"></div>
                
                <a class=" col-6 masscomLogo  masscomLogo_sm" href=" http://newsroom.hcs.com/mfront/board/%EC%8A%A4%ED%83%80%ED%8A%B8%EC%97%85%EA%B3%BC-%EB%8C%80%EA%B8%B0%EC%97%85%EC%9D%B4-%ED%95%A8%EA%BB%98-%EC%84%B1%EC%9E%A5%ED%95%98%EB%8A%94-%EC%83%9D%ED%83%9C%EA%B3%84%EB%A5%BC-%EA%BF%88%EA%BE%BC%EB%8B%A4">
                <img id="logo_img" src="@/assets/hdcardlogo.png" class="img-fluid logo_limit_sm"/>
                </a>
                <div class="w-100"></div>
                <a class=" col-6 masscomLogo  masscomLogo_sm less_margin" href="https://tv.hmgjournal.com/MediaCenter/News/Group-Broadcast/news-02-191104.blg">
                <img id="logo_img" src="@/assets/hmg.jpg" class="img-fluid logo_limit_sm"/>
                </a>
            </div>
        </div>
        
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'MassComs',
  components: {
      
  },
  data : ()=>
  {
      return {
          Greeting_title: "플랫폼과 서비스의 융복합, 기술혁신.",
          Greeting_subtitle: "사운드 제어관련 플랫폼과 차량관련 서비스의 융합을 통해 새로운 가치 창출을 지향합니다.",
      }
  }
}
</script>

<style scoped>
.join{
    padding-top: 12px;
}
.logo_limit_sm{
    max-width: 160px;
}

#logo_img{
    object-position: center;
    object-fit: cover;
    height: auto;
    width: auto;
    /* max-width: 100px; */
    
    display: flex \9;
    flex-wrap: wrap;   
}

#MassComsList_sm{
    /* padding: 8px; */
    margin: 0px;
    align-items: center;
    justify-content: center;
}
.masscomLogo{
    padding:0px;
    margin: 36px;
    height: auto;
    width: 100%;    
}

#MassComsTitle{
  position: relative;
  font-family: 'NanumSquare';
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d3436;
}

.inline_images{
    display: block;
    width: auto;
}

#Line{
  height: 0.5px;
  border: solid 0.5px #636e72;
  position: relative;
  top: 8px;                 /* should it be chagned? */
}

#MassComsContainer{
    position: relative;
    min-width: 320px;
    /* top:-190px; */
}

#MassComsContainer_xs{
    min-width: 320px;
    position: relative;
    padding: 0px;
    top:18px;
}
#MassComsTitle_xs{
  position: relative;
  font-family: 'NanumSquare';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d3436;
}
#MassComsList{
    padding: 0px;
    
    margin-top: 36px;
}

 .masscomLogo_sm{
    /* margin: 50px; */
    /* padding:0px; */
    /* margin: 36px; */
    height: auto;
    width: 100%;    
    min-width: 320px;
    margin-top: 48px;
 }

.less_margin{
    margin-top:0px;
}

/* .img-fluid{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: fill;
    height: auto;
    width: 100%;
} */
</style>