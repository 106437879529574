<template>
  <div id="app">
    <router-view/>
    
    <NavBar/> 
    <CustomFooter/>
  </div>
</template>

<script type="text/javascript">
  import NavBar from '@/components/NavBar.vue'
  import CustomFooter from '@/components/CustomFooter.vue'
  
  export default{
    components:{
      NavBar,
      CustomFooter
    }
  }

</script>

<style>
@font-face {
  font-family: 'NanumSquareB';
  src: url('~@/assets/NanumSquareOTFB.eot'); /* IE9 Compat Modes */
  src: url('~@/assets/NanumSquareOTFB.woff2') format('woff2'), /* Modern Browsers */
    url('~@/assets/NanumSquareOTFB.woff') format('woff'), /* Modern Browsers */
    url('~@/assets/NanumSquareOTFB.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~@/assets/NanumSquareOTFB.svg') format('svg'), /* Legacy iOS */
    url('~@/assets/NanumSquareB.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'NanumSquare';
  src: url('~@/assets/NanumSquareOTFR.eot'); /* IE9 Compat Modes */
  src: url('~@/assets/NanumSquareOTFR.woff2') format('woff2'), /* Modern Browsers */
    url('~@/assets/NanumSquareOTFR.woff') format('woff'), /* Modern Browsers */
    url('~@/assets/NanumSquareOTFR.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~@/assets/NanumSquareOTFR.svg') format('svg'), /* Legacy iOS */
    url('~@/assets/NanumSquareR.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'NanumSquareL';
  src: url('~@/assets/NanumSquareOTFL.otf') format("opentype"); /* IE9 Compat Modes */
  font-weight: normal;
  font-style: normal;
}


#app {
  font-family: 'NanumSquare', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
  /* word-break:keep-all; */
}

/*
deploy hook
https://api.render.com/deploy/srv-bmr2747d1hnithoa9i0g?key=QEo0fFsE9h4
 */

</style>
